<template>
  <q-dialog
    v-model="isOpen"
    persistent
    maximized
  >
    <q-card>
      <q-card-section class="row items-center q-py-sm">
        <div class="text-h6">
          {{ $t('Create product') }}
        </div>

        <q-space />

        <q-btn
          v-close-popup
          icon="close"
          flat
          round
          dense
        />
      </q-card-section>

      <q-card-section>
        <form-builder :schema="schema" />
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
          color="negative"
          :label="$t('Discard')"
          no-caps
          @click="handleClose"
        />

        <q-btn
          color="positive"
          :label="$t('Save')"
          no-caps
          @click="handleOk"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'OfferModal',
  data () {
    return {
      isOpen: false,
      options: {},
      model: {}
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                value: this.model.name,
                label: this.$t('Name'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                required: true,
                onChange: name => {
                  this.model.name = name
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Store'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                value: this.model.shop,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [{ field: 'state', type: 'in', values: ['active', 'blocked'] }]
                  }

                  if (this.options.owner) {
                    query.filter.push({ type: 'eq', field: 'owner', value: this.options.owner })
                  }

                  return this.$service.shop.getAll(query)
                },
                onChange: shop => {
                  this.model.shop = shop
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    resolve () {},
    reject () {},
    show (defaultData = {}, options = {}) {
      this.model = {
        state: 'normal',
        name: '',
        price: 0,
        image: null,
        barcodes: [],
        sku: null,
        ...defaultData
      }
      this.options = options
      this.isOpen = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    handleClose () {
      this.isOpen = false
      this.resolve(null)
    },
    handleOk () {
      const data = { ...this.model }

      if (data.shop) {
        data.shop = data.shop.id
      }

      return this.$service.offer.save(data)
        .then(offer => {
          this.isOpen = false
          this.resolve(offer)
        })
    }
  }
}
</script>
